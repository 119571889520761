<template>
    <data :value="parseFloat(value)">{{ formattedValue }}</data>
</template>

<script>
/*
    For documentation on the underlying formatting mechanism, see the following page on MDN:
    https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
*/

import { formatValue, getFractionDigits } from '@/helpers.js';

export default {
    props: {
        value: undefined,
        locale: String,
        notation: String, // "standard" / "scientific" / "engineering" / "compact"
        unit: String,
        currency: String,
        percent: Boolean,
        options: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        computedOptions() {
            const defaultOptions = {
                notation: this.notation || undefined,
                unit: this.unit || undefined,
                currency: this.currency || undefined,
                style: this.unit
                    ? 'unit'
                    : this.percent
                    ? 'percent'
                    : this.currency
                    ? 'currency'
                    : 'decimal',
            };

            if (this.percent) {
                defaultOptions.maximumFractionDigits = getFractionDigits(
                    this.value * 100,
                );
            }

            return Object.assign(defaultOptions, this.options);
        },
        formattedValue() {
            return formatValue(
                this.value,
                this.computedOptions,
                this.locale || this.$i18n?.locale,
            );
        },
    },
};
</script>
